<template>
  <div>
    <main class="main" role="main">
      <div class=" bg-light h-100">
        <div class="container-fluid">
          <div class="row">
            <div class="left_bg col-sm-2 col-2 h-100  text-white py-2 d-flex align-items-center justify-content-center fixed-top">
              <div class=" pt-4 mb-3 left-banner">
                <div class="logo text-center">
                </div>
              </div>
            </div>
            <div class="col offset-2 offset-sm-2 offset-lg-2 pl-0 pr-0 bg-white rightside_section">
              <div class="border-radius-0 border-0" style="padding:107px 0; background: #F2F2F2;">
                <div class="logo-wrapper text-center mb-2">
                  <img :src="'/'+this.getSiteProps('login.logo')" alt="logo">
                </div>
                <div class="login-main-wrapper">
                  <div class="login-wrapper">
                    <div class="card-header">
                      <h3>Find Username</h3>
                    </div>
                    <div class="card card-body">
                      <b-form @submit="onSubmit" @reset="onReset" v-if="showForm==0">

                        <b-form-group id="input-group-1" label="First Name" label-for="firstname">
                          <b-form-input
                              id="firstname"
                              v-model="form.firstName"
                              placeholder="Enter first name"
                              required
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="last Name" label-for="lastname">
                          <b-form-input
                              id="lastname"
                              v-model="form.lastName"
                              placeholder="Enter last name"
                              required
                          ></b-form-input>
                        </b-form-group>

                        <div class="form-group">
                          <label for="birthday" class="form-label">Date of Birth</label>
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                  id="birthday"
                                  v-model="form.dob"
                                  :pickTime="false"
                                  name="form.dob"
                                  :format="'MM/DD/YYYY'"
                                  :selectableYearRange="{from: 1900, to: 2021}"
                                  v-mask="'##/##/####'"
                                  :isDateDisabled="isFutureDate"
                                  placeholder="MM/DD/YYYY"
                                  :parseDate="parseDatePick"
                              ></date-pick>
                            </div>
                          </div>
                          <span class="text-sm text-danger">{{ errors.dob }}</span>
                        </div>

                        <!--<b-form-group id="input-group-4" label="Email Address" label-for="email"
                            description="Your email was given at the time of registration.">
                          <b-form-input
                              id="email"
                              v-model="form.email"
                              type="email"
                              placeholder="Enter Email"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-5" label="Membership" label-for="membershiptype">
                          <b-form-select
                              id="membershiptype"
                              v-model="form.membershipType"
                              :options="membershipData"
                              plain
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled>&#45;&#45; Select &#45;&#45;</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>-->

                        <b-button type="submit" variant="primary" class="btn-primary btn-red w-100" style="background-color: #05213e;">
                          <i class="fa fa-search"></i> Search</b-button>
                      </b-form>

                      <div v-if="showForm==1">
                        <p>Your Membership Id is: <b>{{ confirmationCode }}</b></p>
                      </div>

                      <div v-if="showForm==2">
                        <p>We cant able to find your record with your given information kindly contact our customer support</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import DatePick from "vue-date-pick";
import axios from "axios";
import moment from 'moment';

export default {
  name: "FindUsername",
  components: {DatePick},
  data() {
    return {
      showForm:0,
      confirmationCode:0,
      form: {
        firstName: "",
        lastName: "",
        dob: "",
        email: "",
        membershipType: null,
      },
      errors:{dob:""},
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.errors.dob = "";
      if (this.validateFindUserFrm()) {
        this.form.dob = this.parseDate(this.form.dob);
        axios.post(process.env.VUE_APP_URL + "api/find-username", this.form)
            .then((response) => {
              let resp = response.data;
              if (resp.status == "success") {
                this.confirmationCode = resp.data.confirmation_code;
                this.showForm = 1;
              } else {
                this.showForm = 2;
              }
            })
            .catch((error) => {
              console.log(error);
            });
      }
    },
    onReset(event) {
      event.preventDefault()
      this.form={
        firstName: "",
        lastName: "",
        dob: "",
        email: "",
        membershipType: "",
      };

      /*this.$nextTick(() => {
        this.show = true
      })*/
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    parseDate(value){
      return moment(value,"MM/DD/YYYY").format("YYYY-MM-DD");
    },
    validateFindUserFrm(){
      const validDob = this.requiredTextField(this.form.dob, 'Date of birth');
      this.errors.dob = validDob.error;

      console.clear();
      console.log(validDob.valid, this.errors.dob);

      return validDob.valid;
    }
  },
  mounted() {
    //this.getMembership();
  },
}
</script>

<style scoped>

</style>